import React from "react"

import { Container, Sidebar } from "../../components"
import Seo from "../../components/seo"
import { ContentWrapper } from "../../elements"

const Success = () => {
  return (
    <Container>
      <Seo />
      <section className="mb-16 mx-auto px-6 max-w-5xl md:mb-24">
        <div
          className="
            flex
            items-center
            justify-between
            mb-6
            pb-2
            border-b border-b border-black
            lg:mb-12 lg:border-0
          "
        >
          <h3 className="flex-none text-xl font-bold md:text-2xl">Contact</h3>
          <div className="hidden flex-grow mx-4 w-full h-px bg-black lg:block"></div>
          <h6 className="flex-none text-xs md:text-sm">お問い合わせ</h6>
        </div>
        <ContentWrapper>
          <div className="col-span-full lg:col-span-2">
            <div className="mb-12">
              <p className="mb-4 text-sm leading-6 md:text-base md:leading-7">
                お問い合わせありがとうございます。
                <br />
                お送り頂いた内容を確認しご返信いたしますので、今しばらくお待ち下さい。
              </p>
            </div>
          </div>
          <Sidebar />
        </ContentWrapper>
      </section>
    </Container>
  )
}

export default Success
